<template>
  <div class="indexbg">
    <div class="topbox leftcard" ref="styleHeight">
      <div class="leftcard">
        <el-card class="box-card" ref="styleHeight" shadow="always">
            <img  class="img" src="~@/assets/image/icon/todo.png">
            <div class="mytabs">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="我的待办" name="first">
                  <mytodo tabName='agenda'></mytodo>
                </el-tab-pane>
                <el-tab-pane label="我的已办" name="third">
                  <mytodo tabName='done'></mytodo>
                </el-tab-pane>
                <el-tab-pane label="我的申请" name="second">
                  <mytodo tabName='app'></mytodo>
                </el-tab-pane>
                <el-tab-pane label="我的消息" name="fourth">
                  <mytodo tabName='info'></mytodo>
                </el-tab-pane>
              </el-tabs>
            </div>
        </el-card>
      </div>
      <div class="rightcard">
        <el-card class="box-card" ref="styleHeight" shadow="always">
          <img  class="img" src="~@/assets/image/icon/notice.png">
          <div class="notice">
            <div class="borderheader">系统公告</div>
            <system-notice></system-notice>
          </div>
        </el-card>
      </div>
    </div>
    <div class="bottomcard">
      <el-card class="box-card" shadow="always">
        <img  class="img" src="~@/assets/image/icon/data.png">
        <div class="dataView">
          <div class="dataViewheader">数据视图</div>
            <div class="clearfix pdexchart">
              <div class="exChartBox">
                <div class="pickerData clearfix" >
                  <div class="shSuptotal">送货需求</div>
                      <div class="block" >
                        <el-date-picker
                          v-model="date"
                          type="daterange"
                          @change="change"
                          range-separator="-"
                          start-placeholder="请选择"
                          :default-value="[beginDate, endDate]"
                          end-placeholder="请选择">
                        </el-date-picker>
                      </div>
                </div>
                <div class="indexcharts">
                    <pie-chart :beginDate="beginDate" :endDate="endDate" style="top:-30px"></pie-chart>
                </div>
              </div>
              <div class="exChartBox">
                <div class="suptotal">合同签约</div>
                <div class="indexcharts">
                    <index-chart style="top:-30px"></index-chart>
                </div>
              </div>
              <div class="exChartBox" v-if="supShow">
                <div class="pickerData clearfix">
                  <div class="Suptotaltotal">供应商总数量:</div>
                  <div class="indexcharts">
                    <index-charts style="top:-30px"></index-charts>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Mytodo from '@/views/index/module/Mytodo'
import IndexCharts from '@/views/index/module/IndexCharts'
import IndexChart from '@/views/index/module/IndexChart'
import pieChart from '@/views/index/module/pieChart'
import SystemNotice from '@/views/index/module/SystemNotice'
import dayjs from 'dayjs'
export default {
  name: 'ShowBox',
  // IndexChart
  components: { Mytodo, IndexCharts, pieChart, SystemNotice, IndexChart },
  data () {
    return {
      clHeight: document.documentElement.clientHeight,
      activeName: 'first',
      activeNametwo: '',
      drawer: false,
      supShow: false,
      date: [],
      beginDate: dayjs(new Date(new Date(sessionStorage.getItem('serversDate')).getTime() - 7 * 24 * 3600 * 1000)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(new Date(sessionStorage.getItem('serversDate'))).format('YYYY-MM-DD 23:59:59')
    }
  },
  created () {
    if (this.$store.state.loginInfo.userType === 0) {
      this.supShow = true
    } else {
      this.supShow = false
    }
    this.date = []
    this.date.push(this.beginDate)// 设置默认时间
    this.date.push(this.endDate) // 设置默认时间
    if (this.$store.state.loginInfo.enterpriseCode === null && this.$store.state.loginInfo.userType === 1) {
      this.$router.push({ name: 'frame', params: { frameName: 'myCompany', viewName: 'SupplierRegistration' } })
    }
  },
  watch: {
    '$store.state.loginInfo': function () {
      if (this.$store.state.loginInfo.enterpriseCode === null && this.$store.state.loginInfo.userType === 1) {
        this.$router.push({ name: 'frame', params: { frameName: 'myCompany', viewName: 'SupplierRegistration' } })
      }
      if (this.$store.state.loginInfo.userType === 0) {
        this.supShow = true
      } else {
        this.supShow = false
      }
      this.date = []
      this.beginDate = dayjs(new Date(new Date(sessionStorage.getItem('serversDate')).getTime() - 7 * 24 * 3600 * 1000)).format('YYYY-MM-DD HH:mm:ss')
      this.endDate = dayjs(new Date(sessionStorage.getItem('serversDate'))).format('YYYY-MM-DD 23:59:59')
      this.date.push(this.beginDate)
      this.date.push(this.endDate)
    }
  },
  methods: {
    handleClose () { // 右侧弹出框
      this.drawer = false
    },
    change (data) {
      this.beginDate = dayjs(data[0]).format('YYYY-MM-DD HH:mm:ss')
      this.endDate = dayjs(data[1]).format('YYYY-MM-DD 23:59:59')
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.indexbg{
  margin: 20px;
  .img{
    width: 35px;
    height: 35px;
    margin:0px 0px -35px 0px;
    display: inline-block;
  }
  :deep(.el-card__body){
    padding: 0px 20px 20px 20px;
  }
  :deep(.el-button--text){
    color: #666666;
  }
  :deep(.el-tabs__item){
    top: -10px;
  }
  :deep(.el-tabs__item.is-active){
    font-weight: 600;
  }
.dataViewheader{
  font-size: 16px;
  padding: 19px 0px 19px 45px;
  border-bottom: 2px solid #E4E7ED;
  color: #409EFF;
  font-weight: 600;
}
.topbox{
  width: 100%;
  min-height: 400px;
  margin-bottom: 20px; //1
  height: calc(100vh - 563px);
  .box-card{
    height: 100%; //1
  }
  :deep(.el-card.is-always-shadow){
    height: 100%; //1
  }
  :deep(.el-tabs__content){
    min-height: 68px;
    overflow: auto;
  }
  :deep(.el-tabs__nav-scroll){
    height: 60px;
    text-align: center;
  }
  .leftcard{ //左侧card
  float: left;
  width: 60%;
  height: 100%;
  .mytabs{
    margin: -40px 5px 0px 45px;
  }
}
  :deep(.el-tabs__header){
    margin: 19px 0px 0px 0px;
  }
  :deep(.el-tabs__nav){
    margin-top: 20px;
  }
  :deep(.el-tabs__content){
  top: 0px;
  }
  :deep(.el-tabs__item){
  font-size: 16px;
  }
  .rightcard{
    width: 39%;
    height: 100%;
    float: right;
    .notice{
      margin: -21px 5px 0px 45px;
    }
  .borderheader{
    font-size: 16px;
    padding: 18px 0px 19px 0px;
    border-bottom: 2px solid #E4E7ED;
    color: #409EFF;
    font-weight: 600;
 }
  .border{
    padding: 10px;
    font-size: 14px;
    margin: 20px 0px;
    border-bottom: 1px solid #E4E7ED;
  }
}
}
.bottomcard{
  width: 100%;
  height: 100%;
  position: relative;
  .pdexchart{
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .exChartBox{
    width: 100%;
    text-align: center;
    .pickerData{
      position: relative;
      z-index: 2;
      width: 320px;
      margin: 0 auto;
    }
  }
  .dataView{
    margin: -20px 5px 0px 0;
  }
  .indexcharts{
    width: 380px;
    height: 300px;
    margin: 30px;
    display: inline-block;
    margin: 0 auto;
  }
  .suptotal{
    height: 30px;
    font-size: 16px;
    color: #666;
  }
  .shSuptotal{
    width: 80px;
    float: left;
    margin-top: 5px;
    color: #666;
  }
  .cxbtn{
    float: right;
    margin: 2px -55px 0px 0px;
  }
  :deep(.el-button--text){
    color:#409EFF ;

  }
  .Suptotaltotal{
    width: 100px;
    float: left;
    margin: 5px 0px 0px 40px;
    color: #666;
  }
.block{
  width: 180px;
  height: 30px;
  float: left;
  font-size: 16px;
}
:deep(.el-input__inner){
  width: 225px;
}
}
:deep(.element.style){
  background-color: #666666;
}
}

</style>
